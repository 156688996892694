import { db } from 'core/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage'

const storage = getStorage()

const getAdditionalPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
  definedInventoryPhotos: VehiclePhoto[],
): Promise<VehiclePhoto[]> => {
  const storageRef = ref(storage, `${collectionName}/${inventoryObjectId}`)

  const photos: VehiclePhoto[] = []

  const storageObjects = await listAll(storageRef)

  for (const obj of storageObjects.items) {
    const url = await getDownloadURL(obj)

    if (definedInventoryPhotos.some(photo => photo.url === url)) {
      continue
    } else {
      photos.push({
        id: inventoryObjectId,
        name: obj.name,
        fullPath: obj.fullPath,
        url,
      })
    }
  }
  return photos
}

export const getPhotosForInventoryObject = async (collectionName: string, inventoryObjectId: string): Promise<VehiclePhoto[]> => {
  const inventoryDocRef = doc(db, collectionName, inventoryObjectId)

  const docSnap = await getDoc(inventoryDocRef)
  const definedPhotos: VehiclePhoto[] = []

  if (!docSnap.exists()) {
    console.error('No such document!')
    return []
  }
 
  const data = docSnap.data() 

  if (!data) {
    console.error('No data found for document!')
    return []
  }

  const errorPhotos: unknown[] = []

  if (data.photos) {
    const photos: Record<string, string> = data.photos;
    for (const [key, value] of Object.entries(photos)) {
      try {
        const storageRef = ref(storage, `${collectionName}/${inventoryObjectId}/${value}`)


        if (!storageRef) {
          continue
        }
        console.log('photo', key, value)
        console.log('storageRef', storageRef)
        const url = await getDownloadURL(storageRef)
        console.log('url', url)

        definedPhotos.push({
          id: inventoryObjectId,
          name: value,
          url: url,
          position: key,
          fullPath: ''
        })
      } catch (error) {
        console.error('Error getting download URL for photo', value, error)
        errorPhotos.push(value)
      }
    }
  }

  const additionalPhotos = await getAdditionalPhotosForInventoryObject(collectionName, inventoryObjectId, definedPhotos)

  return [...definedPhotos, ...additionalPhotos]
}
