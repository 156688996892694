import { deleteDoc, doc, updateDoc } from '@firebase/firestore'
import { CarCrash, PhotoAlbumOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import TruckIcon from '@mui/icons-material/LocalShipping'
import { Box, Button, Grid, Switch } from '@mui/material'
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid'
import { SelectionButtonT } from 'components/table/DataGridToolBar'
import { PageTitle } from 'components/PageTitle'
import QuickFilter, { genericDataObject } from 'components/QuickFilter'
import { useAuth } from 'contexts/AuthContext'
import { downloadShareableInventoryCsv } from 'core/api/inventory/downloadShareableInventoryCSV'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import { handleInventoryCsvUpload } from 'core/api/inventory/handleInventoryCsvUpload'
import removeFromWebsiteInventory from 'core/api/inventory/removeFromWebsiteInventory'
import { addToWebsiteInventory } from 'core/api/inventory/showHideOnWebsiteInventory'
import { db } from 'core/config/firebase'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { MainLayout } from 'layouts'
import React, { FC, useEffect, useState } from 'react'
import VehicleCard from './components/Cards/VehicleCard'
import VehicleForm from './components/Forms/VehicleForm'
import ManagePhotosDialog from './components/ManagePhotosDialog'
import PhotoUploadDialog from './components/PhotoUploadDialog'
import SelectMainPhotoDialog from './components/SelectMainWebsitePhotoDialog'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import { useSnackbar } from 'contexts/snackBarContext'
import DataGridFirestoreCRUD from 'components/table/DataGridFirestoreCRUD'
import { formatDateTime, formatDistance, formatPrice } from 'core/utils/inventoryUtils'

const vehicleStatusOptions = ['Pending', 'Available', 'Pending Sale', 'Sold']

interface InventoryManagementProps {
  inventoryCollectionName: string
}

const InventoryManagement: FC<InventoryManagementProps> = ({ inventoryCollectionName }) => {
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false)
  const [openManagePhotosDialog, setOpenManagePhotosDialog] = useState(false)
  const [openSelectMainPhotoDialog, setOpenSelectMainPhotoDialog] = useState(false)
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const { showSnackbar } = useSnackbar()

  const { userInfo } = useAuth()
  const uType = userInfo?.user_type

  const isMobile = useIsMobile()
  const isOpticInventory = inventoryCollectionName === 'master_inventory'
  const userGuidelines = [
    'Upload CSV to add multiple trucks at once.',
    'You can edit the inventory details by double clicking on the row. Select multiple rows to delete them.',
    "Use the 'Send Photo Upload' button to send a photo upload to a representative.",
    "Use the 'Associate Finance Application' button to associate a finance application with an existing inventory item.",
  ]
  const columns: GridColDef[] = [
    {
      field: 'date_added',
      headerName: 'Timestamp',
      valueFormatter: formatDateTime,
    },
    { field: 'vin', headerName: 'VIN', editable: true },
    { field: 'stock_number', headerName: 'Stock Number', editable: true },
    { field: 'make', headerName: 'Make', editable: true },
    { field: 'model', headerName: 'Model', editable: true },
    { field: 'size', headerName: 'Size', editable: true },
    { field: 'fuel', headerName: 'Fuel', editable: true },
    { field: 'miles', headerName: 'Mileage', editable: true, valueFormatter: formatDistance },
    { field: 'year', headerName: 'Year', editable: true },
    { field: 'condition', headerName: 'Condition', editable: true },
    { field: 'location', headerName: 'Location', editable: true },
    { field: 'who', headerName: 'Who', editable: true },
    { field: 'type', headerName: 'Type', editable: true },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: vehicleStatusOptions,
    },
  ]
  if (userInfo?.user_type === 'admin') {
    columns.push({
      field: 'optic_list_price',
      headerName: 'Buy Price',
      valueFormatter: formatPrice,
    })
  }

  columns.push({
    field: 'seller_asking_price',
    headerName: 'Sale Price',
    valueFormatter: formatPrice,
  })

  if (userInfo?.user_type === 'admin') {
    columns.push({
      field: 'profit',
      headerName: 'Profit',
      valueFormatter: (value: string | undefined) => {
        if (!value) {
          return '';
        }
  
        const cleanedValue = value.replace(/[$,]/g, '');
  
        const numValue = Number(cleanedValue);
        if (isNaN(numValue)) {
          return '';
        }
  
        const hasDecimals = numValue % 1 !== 0;
        return `$${numValue.toLocaleString(undefined, { minimumFractionDigits: hasDecimals ? 2 : 0 })}`;
      },
    });
  }
  

  columns.push({
    field: 'url',
    headerName: 'URL',
    minWidth: 80,
    renderCell: params =>
      params.value &&
      params.value.length > 0 && (
        <Button
          variant='text'
          onClick={() => window.open(params.value, '_blank', 'noopener,noreferrer')}
          sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
        >
          View
        </Button>
      ),
  })

  columns.push({
    field: 'Photos',
    type: 'actions',
    headerName: 'Photos',
    editable: false,
    flex: 1,
    getActions: params => [
      <GridActionsCellItem
        key={params.id}
        icon={<PhotoAlbumOutlined />}
        label='Send Reminder Email'
        onClick={() => handleManagePhotosClick(params.row)}
        color='inherit'
        disabled={params.row.email_verified}
      />,
    ],
  })
  columns.push({
    field: 'show_hide',
    type: 'actions',
    headerName: 'Show Hide',
    editable: false,
    flex: 1,
    getActions: params => [
      <Switch
        key={params.id}
        checked={params.row.show_on_website}
        icon={<VisibilityOff />}
        checkedIcon={<Visibility />}
        onChange={() => handleShowHideOnWebsiteClick(params.row)}
        color={params.row.show_on_website ? 'success' : 'error'}
      />,
    ],
  })
  columns.push({
    field: 'Photo Upload',
    type: 'actions',
    headerName: 'Photo Upload',
    editable: false,
    flex: 1,
    getActions: params => [
      <Switch
        key={params.id}
        checked={params.row.show_on_website}
        icon={<VisibilityOff />}
        checkedIcon={<Visibility />}
        onChange={
          isOpticInventory
            ? () => handleShowHideOnWebsiteClick(params.row)
            : () => {
                showSnackbar('Only available for Optic Inventory', 'error')
              }
        }
        color={params.row.show_on_website ? 'success' : 'error'}
      />,
    ],
  })
  columns.push({ field: 'Sold Date', headerName: 'Sold Date' })

  const handleShareInventoryListClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())
    downloadShareableInventoryPdf(inventoryCollectionName, masterInventoryIds)
  }

  const handleDownloadInventoryCSVClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())

    if (uType) {
      downloadShareableInventoryCsv(inventoryCollectionName, masterInventoryIds, uType)
    }
  }

  const customToolbarSelectionButtons: SelectionButtonT[] = [
    {
      icon: <CarCrash />,
      label: 'Download PDF',
      onClick: handleShareInventoryListClick,
    },
    {
      icon: <CarCrash />,
      label: 'Download CSV',
      onClick: handleDownloadInventoryCSVClick,
    },
  ]

  const {
    data: vehicles,
    isLoading,
    isSuccess: loadedVehicles,
    refetch: refetchVehicles,
  } = useQueryFirestoreDocuments({
    inventoryCollectionName,
    useQueryOptions: {
      subscribe: true,
    },
  })

  useEffect(() => {
    refetchVehicles
  }, [loadedVehicles, inventoryCollectionName])

  useEffect(() => {
    if (vehicles) {
      // can be deleted when no duplicate vins loaded via csv
      const uniqueVehicles = (vehicles as Vehicle[]).filter(
        (vehicle, index, self) => index === self.findIndex(v => v.vin === vehicle.vin),
      )
      setFilteredVehicles(uniqueVehicles)
    }
  }, [loadedVehicles])

  const { mutate: addFirebaseDocument } = useAddFirestoreDocument({ collectionName: inventoryCollectionName })

  // TODO: Replace with react-query
  const handleDeleteVehicle = async (vehicleId: string) => {
    const inventoryRef = doc(db, inventoryCollectionName, vehicleId)
    await deleteDoc(inventoryRef)
  }

  const handleAddVehicle = async (data: { [key: string]: string | number | boolean }) => {
    const newValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      const opticListPrice = Number(newValues.optic_list_price) || 0 // Default to 0 if not valid
      const sellerAskingPrice = Number(newValues.seller_asking_price) || 0 // Default to 0 if not valid

      // Calculate profit: sale price - buy price and convert to string
      newValues.profit = (sellerAskingPrice - opticListPrice).toString()
    } else {
      newValues.profit = '0' // Set profit to '0' if prices are not provided
    }
    addFirebaseDocument(newValues)
    setOpenVehicleForm(false)
  }

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    if (typeof sanitizedData.miles === 'string') {
      sanitizedData.miles = sanitizedData.miles.replace(/,/g, '')
    }
    if (typeof sanitizedData.optic_list_price === 'string') {
      sanitizedData.optic_list_price = sanitizedData.optic_list_price.replace(/,/g, '')
    }
    if (typeof sanitizedData.seller_asking_price === 'string') {
      sanitizedData.seller_asking_price = sanitizedData.seller_asking_price.replace(/,/g, '')
    }
    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      const opticListPrice = Number(newValues.optic_list_price) || 0 // Default to 0 if not valid
      const sellerAskingPrice = Number(newValues.seller_asking_price) || 0 // Default to 0 if not valid

      // Calculate profit: sale price - buy price and convert to string
      newValues.profit = (sellerAskingPrice - opticListPrice).toString()
    } else {
      newValues.profit = '0' // Set profit to '0' if prices are not provided
    }

    try {
      const docRef = doc(db, inventoryCollectionName, selectedVehicle.id)
      await updateDoc(docRef, newValues)
      setOpenVehicleForm(false)
    } catch (error) {
      showSnackbar('Error updating vehicle', 'error')
      console.error('Error updating vehicle:', error)
    }
  }

  const handleManagePhotosClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle as Vehicle)
    setOpenManagePhotosDialog(true)
  }

  const handleShowHideOnWebsiteClick = async (vehicle: Vehicle) => {
    if (vehicle.show_on_website !== true) {
      setOpenSelectMainPhotoDialog(true)
    }

    const inventoryDocRef = doc(db, inventoryCollectionName, vehicle.id)

    if (vehicle.show_on_website) {
      await removeFromWebsiteInventory(vehicle.id)
    } else {
      await addToWebsiteInventory(vehicle.id)
    }
    await updateDoc(inventoryDocRef, { show_on_website: !vehicle.show_on_website })
  }

  const handlePhotoUploadClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenPhotoUploadDialog(true)
  }

  const handleClickVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleForm(true)
  }

  const handleFilterResults = (results: genericDataObject[]) => {
    setFilteredVehicles(results as unknown as Vehicle[])
  }

  const handleDownloadCSVTemplate = () => {
    window.open('/inventory_template.csv')
  }

  return (
    <MainLayout>
      <PageTitle
        title={`${isOpticInventory ? 'Optic' : 'Shield'} Inventory Management`}
        subtitle='Manage the inventory of trucks.'
        bulletPoints={userGuidelines}
      />
      <Box
        maxWidth={isMobile ? '100%' : '400px'}
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        alignItems='center'
        sx={{ marginBottom: 2, gap: isMobile ? 2 : 1 }}
      >
        {!isMobile && (
          <>
            <Button
              variant='contained'
              component='label'
              fullWidth={isMobile}
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              onClick={() => handleDownloadCSVTemplate()}
            >
              Download CSV Template
            </Button>

            <Button
              variant='contained'
              component='label'
              fullWidth={isMobile}
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
            >
              Upload CSV
              <input
                type='file'
                hidden
                onChange={event => handleInventoryCsvUpload(event, inventoryCollectionName)}
              />
            </Button>
          </>
        )}
        <Button
          variant='contained'
          startIcon={<TruckIcon />}
          onClick={() => {
            setSelectedVehicle(null)
            setOpenVehicleForm(true)
          }}
          fullWidth={isMobile}
          sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
        >
          Add Truck
        </Button>
      </Box>
      {isLoading ? (
        <div>Loading Vehicles...</div>
      ) : isMobile ? (
        <Box display='flex' flexDirection='column' gap={2}>
          <QuickFilter
            data={(vehicles as unknown as genericDataObject[]) ?? []}
            onFilterResults={handleFilterResults}
          />
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: 'center',
              flexWrap: 'wrap',
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {!isLoading &&
              filteredVehicles &&
              filteredVehicles.map(vehicle => (
                <Grid item key={vehicle.id} xs={12} sm={6} md={4} lg={3}>
                  <VehicleCard
                    collectionName={inventoryCollectionName}
                    vehicle={vehicle}
                    onClick={() => handleClickVehicle(vehicle)}
                    onDeleteClick={() => handleDeleteVehicle(vehicle.id)}
                    onManagePhotosClick={() => handleManagePhotosClick(vehicle)}
                    onPhotoUploadClick={() => handlePhotoUploadClick(vehicle)}
                    onShowHideOnWebsiteClick={() => handleShowHideOnWebsiteClick(vehicle)}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <DataGridFirestoreCRUD
          columns={columns}
          hiddenColumns={['engine', 'GVW', 'boxSize', 'transmission']}
          collectionName={inventoryCollectionName}
          editable={false}
          deleteable={true}
          create={true}
          search={true}
          multiDeleteable={true}
          onDeleteClick={handleDeleteVehicle}
          onDoubleClick={id => handleClickVehicle(vehicles?.find(vehicle => vehicle.id === id) as Vehicle)}
          customToolbarSelectionButtons={customToolbarSelectionButtons}
          viewOnlyMode={true}
          canShowDocViewer={userInfo?.email.includes('freetech.co')}
        />
      )}
      <VehicleForm
        open={openVehicleForm}
        vehicle={selectedVehicle}
        onClose={() => setOpenVehicleForm(false)}
        onSubmit={selectedVehicle ? handleEditVehicle : handleAddVehicle}
        generateStockNumber={() =>
          generateOpticStockNumber(
            (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
        }
      />
      {selectedVehicle && (
        <>
          <PhotoUploadDialog
            open={openPhotoUploadDialog}
            onClose={() => setOpenPhotoUploadDialog(false)}
            vehicle={selectedVehicle}
          />
          <ManagePhotosDialog
            collectionName={inventoryCollectionName}
            open={openManagePhotosDialog}
            onClose={() => setOpenManagePhotosDialog(false)}
            vehicle={selectedVehicle}
          />
          <SelectMainPhotoDialog
            collectionName={inventoryCollectionName}
            open={openSelectMainPhotoDialog}
            onClose={() => setOpenSelectMainPhotoDialog(false)}
            vehicle={selectedVehicle}
          />
        </>
      )}
    </MainLayout>
  )
}

export default InventoryManagement
