import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { SellSubmission } from 'core/types/form_submission'
import React from 'react'
import FleetListDialog from '../FleetListDialog'

interface SellSubmissionListItemProps {
  submission: SellSubmission
  actionButton: JSX.Element
  onClick: () => void
  onSubmit: () => void
}

const SellSubmissionListItem: React.FC<SellSubmissionListItemProps> = ({
  submission,
  onClick,
  onSubmit,
  actionButton,
}) => {
  const [openFleetDialog, setOpenFleetDialog] = React.useState(false)

  return (
    <>
      <ListItem>
        <ListItemButton onClick={onClick}>{actionButton}</ListItemButton>
        <ListItemText
          primary={`${submission.firstName} ${submission.lastName}`}
          secondary={
            <>
              <Typography component='span' variant='body2' color='text.primary'>
                {`${submission.firstName} ${submission.lastName}`}
              </Typography>
              {` - ${submission.email}`}
            </>
          }
        />
      </ListItem>
      <FleetListDialog
        open={openFleetDialog}
        fleetOwner={`${submission.firstName} ${submission.lastName}`}
        onClose={() => setOpenFleetDialog(false)}
        file={submission.fileBase64?.data ?? ''}
        onSubmit={() => {
          onSubmit()
          setOpenFleetDialog(false)
        }}
      />
    </>
  )
}

export default SellSubmissionListItem
