import { Button, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { generateNewApplicationUrl, postFinishedApplication } from 'core/api/application/client'
import { PandaDocument } from 'core/types/applications'
import { MainLayout } from 'layouts'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PandadocFrame from './PandadocFrame'

const ApplicationNew = () => {
  const [iframeUrl, setIframeUrl] = useState('')
  const [formCompleted, setFormCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState<number | null>(null)
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setFormCompleted(true)
    setLoading(true)

    generateNewApplicationUrl().then(generatedApplication => {
      setIframeUrl(generatedApplication.url)
      setApplicationId(generatedApplication.application_id)
      setLoading(false)
    })
  }

  const onFinishAppliction = (pandaPayload: PandaDocument) => {
    if (!applicationId) {
      console.error('Application id missing', 'error')
      return
    }
    const pandadata = {
      document_uuid: pandaPayload.document.id,
      date_completed: pandaPayload.document.date_completed,
      template_uuid: pandaPayload.document.created_from_template.id,
    }
    postFinishedApplication({ application_id: applicationId, ...pandadata }).then(res => {
      if (res.status === 200) {
        showSnackbar('Application Submitted Successfully!', 'success')
        navigate('/client')
      } else {
        showSnackbar('Application Failed to submit!', 'error')
      }
    })
  }

  return (
    <MainLayout>
      {!formCompleted && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <Typography variant='h5' component='h2' gutterBottom>
              We have partnered with PandaDoc to provide you a secure and easy way to complete your financing
              process.
            </Typography>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            <Typography variant='h5' component='h2' gutterBottom>
              Click the button below to begin your application.
            </Typography>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            <form onSubmit={handleSubmit}>
              <Button type='submit' variant='contained' color='primary' sx={{ marginTop: 2 }}>
                Begin Application
              </Button>
            </form>
          </Grid>
        </Grid>
      )}

      {formCompleted && (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%', height: 'calc(100vh - 64px)' }}
        >
          {!loading && (
            <Typography component='p' gutterBottom marginTop={2} marginBottom={2}>
              We have partnered with PandaDoc to provide you with a secure and easy way to complete your application.
              Please contact support@optictruckworks.com <></>
              <br />
              if you have any questions.
            </Typography>
          )}
          <PandadocFrame
            loading={loading}
            iframeUrl={iframeUrl}
            onFormCompleted={onFinishAppliction}
            onIframeLoad={() => setLoading(false)}
          />
        </Grid>
      )}
    </MainLayout>
  )
}

export default ApplicationNew
