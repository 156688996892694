import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormField } from 'core/types/forms'
import React, { Fragment, useEffect, useState } from 'react'
interface CustomFormProps {
  fields: FormField[]
  submitText: string
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  onCancel: () => void
  initialValues?: { [key: string]: string | number | boolean | unknown }
  subTitles?: { [beforeFieldNumber: number]: string }
}

// FIXME: validation

const CustomForm: React.FC<CustomFormProps> = ({
  fields,
  onSubmit,
  subTitles,
  submitText,
  onCancel,
  initialValues,
}) => {
  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    const existingValues = Object.assign(
      {},
      ...fields.map(field => {
        if (field.type === 'select' && (!initialValues || !initialValues[field.name])) {
          return { [field.name]: field?.selectValues?.[0] }
        } else {
          return { [field.name]: initialValues ? initialValues[field.name] : '' }
        }
      }),
    )
    setValues(existingValues)
  }, [fields, initialValues])

  const validateFieldValue = (field: FormField, value: string | boolean | number) => {
    if (!field.validation) return

    if (!value && !field.required) return

    if (!value && field.required) return 'This field is required'

    if (field.validation.validateValue && !field.validation.validateValue(value)) {
      return field.validation.errorText || 'Invalid value'
    }

    return ''
  }

  const handleChange = (field: FormField, value: string | boolean | number) => {
    validateFieldValue(field, value)
    setValues(prevValues => ({ ...prevValues, [field.name]: value }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newErrors: { [key: string]: string } = {}
    let hasErrors = false

    fields.forEach(field => {
      const error = validateFieldValue(field, values[field.name])
      if (error) {
        newErrors[field.name] = error
        hasErrors = true
      }
    })

    setErrors(newErrors)

    if (!hasErrors) {
      onSubmit(values)
    }
  }

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit}
      height={{ xs: '100%', md: 'auto' }}
      width={{ xs: '100%', md: 'auto' }}
      overflow='hidden'
    >
      <Box flex={1} height='calc(100% - 64px)' sx={{ overflowY: 'auto' }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            return (
              <Fragment key={`${subTitles && subTitles[index] ? `subtitle-${index}-` : '-'}${field.name}`}>
                {subTitles && subTitles[index] && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      {subTitles[index]}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={field.fullWidth ? 12 : 6}>
                  {field.viewOnly ? (
                    <Box>
                      <Typography variant='h5' fontWeight='medium'>
                        {field.label}
                      </Typography>
                      <Typography fontWeight='light'>{values[field.name]}</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <InputLabel
                        sx={{
                          color: 'black',
                          mb: 1,
                          display: field.showTitle ? 'block' : 'none',
                          fontWeight: 'medium',
                        }}
                      >
                        {field.label}
                      </InputLabel>
                      {field.type === 'text' && (
                        <TextField
                          {...(field.textFieldProps || {})}
                          InputProps={field.button ? {
                            endAdornment: (
                              <InputAdornment position='end'>{field.button(values[field.name])}</InputAdornment>
                            )
                          } : undefined}
                          required={field.required}
                          fullWidth
                          id={field.name}
                          label={field.showTitle ? null : field.label}
                          name={field.name}
                          value={values[field.name] || ''}
                          onChange={event => handleChange(field, event.target.value)}
                          error={Boolean(errors[field.name])}
                          helperText={errors[field.name]}
                        />
                      )}
                      {field.type === 'select' && (
                        <FormControl fullWidth>
                          {!field.showTitle && <InputLabel id={field.name}>{field.label}</InputLabel>}
                          <Select
                            label={field.showTitle ? null : field.label}
                            name={field.name}
                            value={values[field.name as keyof typeof values] ?? field?.selectValues?.[0]}
                            onChange={event => handleChange(field, String(event.target.value))}
                          >
                            {field.selectValues?.map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {field.type === 'check' && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(values[field.name]) || false}
                              onChange={event => handleChange(field, event.target.checked)}
                              name={field.name}
                            />
                          }
                          label={field.label}
                        />
                      )}
                      {field.type == 'action' && field.actionElement && field.actionElement()}
                    </Box>
                  )}
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        alignItems='center'
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '64px',
        }}
      >
        <Button type='button' color='secondary' onClick={onCancel}>
          Cancel
        </Button>
        <Button type='submit' variant='contained'>
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}

export default CustomForm
