import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardProps,
  Typography
} from '@mui/material'
import { SellSubmission } from 'core/types/form_submission'
import React from 'react'
import TrucksImage from '../../../../../../assets/Trucks.jpeg'
import FleetListDialog from '../FleetListDialog'

interface SubmissionFormProps extends CardProps {
  submission: SellSubmission
  onClick: () => void
  onSubmit: () => void
}

const SellSubmissionCard: React.FC<SubmissionFormProps> = ({ submission, onClick, onSubmit, ...cardProps }) => {
  const [openFleetDialog, setOpenFleetDialog] = React.useState(false)

  return (
    <Card {...cardProps} sx={{ minWidth: 275 }}>
        <CardMedia
          component='img'
          src={TrucksImage}
          alt='Trucks'
          loading='lazy'
          height={200}
          onClick={e => {
            e.stopPropagation()
            setOpenFleetDialog(true)
          }}
        />
      <CardHeader
        title={
          <Box>
            <Typography variant='h5'>{`${submission.firstName} ${submission.lastName}`}</Typography>
          </Box>
        }
        sx={{ pb: 1 }}
      />
      <CardContent onClick={onClick} sx={{ pt: 1 }}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box>
            <Typography variant='h6' sx={{ mb: 1 }}>
              Contact Information
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              <Typography>
                <strong>Email:</strong> {submission.email}
              </Typography>
              <Typography>
                <strong>Phone:</strong> {submission.phone}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <FleetListDialog
        open={openFleetDialog}
        fleetOwner={`${submission.firstName} ${submission.lastName}`}
        onClose={() => setOpenFleetDialog(false)}
        file={submission.fileBase64?.data ?? ''}
        onSubmit={() => {
          onSubmit()
          setOpenFleetDialog(false)
        }}
      />
    </Card>
  )
}


export default SellSubmissionCard
