import { Box, Grid, Paper, Fab, Tooltip, Typography } from '@mui/material'
import { LineChart, PieChart } from '@mui/x-charts'
import { useAdmin } from 'contexts/AdminContext'
import Main from 'layouts/MainLayout'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Inventory2 as StockIcon } from '@mui/icons-material'

const SuperAdminHome = () => {
  const { applications, statusCounts } = useAdmin();
  const [applicationMonths, setApplicationMonths] = useState<Date[]>([]);
  const navigate = useNavigate();
  const [statusCountsByMonth, setStatusCountsByMonth] = useState({
    ready: [] as number[],
    correctionsNeeded: [] as number[],
    approved: [] as number[],
    denied: [] as number[],
    viewed: [] as number[],
    pending: [] as number[],
  });

  useEffect(() => {

    const months: Date[] = [];
    const statusCountsByMonth = {
      ready: [] as number[],
    correctionsNeeded: [] as number[],
    approved: [] as number[],
    denied: [] as number[],
    viewed: [] as number[],
    pending: [] as number[],
    };

    applications.forEach(application => {
      const date = new Date(application.time_created);
      const monthYear = new Date(date.getFullYear(), date.getMonth(), 1);
      const monthIndex = months.findIndex(m => m.getTime() === monthYear.getTime());

      if (monthIndex === -1) {
        months.push(monthYear);
        statusCountsByMonth.ready.push(application.status === 'Ready' ? 1 : 0);
        statusCountsByMonth.correctionsNeeded.push(application.status === 'Corrections Needed' ? 1 : 0);
        statusCountsByMonth.approved.push(application.status === 'Approved' ? 1 : 0);
        statusCountsByMonth.denied.push(application.status === 'Denied' ? 1 : 0);
        statusCountsByMonth.viewed.push(application.status === 'Viewed' ? 1 : 0);
        statusCountsByMonth.pending.push(application.status === 'Pending' ? 1 : 0);
      } else {
        if (application.status === 'Ready') statusCountsByMonth.ready[monthIndex] += 1;
        if (application.status === 'Corrections Needed') statusCountsByMonth.correctionsNeeded[monthIndex] += 1;
        if (application.status === 'Approved') statusCountsByMonth.approved[monthIndex] += 1;
        if (application.status === 'Denied') statusCountsByMonth.denied[monthIndex] += 1;
        if (application.status === 'Viewed') statusCountsByMonth.viewed[monthIndex] += 1;
        if (application.status === 'Pending') statusCountsByMonth.pending[monthIndex] += 1;
      }
    });

    setApplicationMonths(months);
    setStatusCountsByMonth(statusCountsByMonth);
  }, [applications]);


  return (
    <Main>
      <Box margin={4}>
        <Typography variant='h3'>Business Overview</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant='h5' gutterBottom>
              Application Status Distribution
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: statusCounts.ready, label: 'Ready' },
                    { id: 1, value: statusCounts.correctionsNeeded, label: 'Corrections Needed' },
                    { id: 2, value: statusCounts.approved, label: 'Approved' },
                    { id: 3, value: statusCounts.denied, label: 'Denied' },
                    { id: 4, value: statusCounts.viewed, label: 'Viewed' },
                    { id: 5, value: statusCounts.pending, label: 'Pending' },
                  ],
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                },
              ]}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant='h5' gutterBottom>
              Application by Month
            </Typography>
            <LineChart
              xAxis={[
                {
                  id: 'Months',
                  data: applicationMonths,
                  scaleType: 'time',
                  valueFormatter: date => `${date.getMonth() + 1}/${date.getFullYear()}`,
                },
              ]}
              series={[
                { id: 'Ready', label: 'Ready', data: statusCountsByMonth.ready, color: 'blue', showMark: false },
                { id: 'Corrections Needed', label: 'Corrections Needed', data: statusCountsByMonth.correctionsNeeded, color: 'orange', showMark: false },
                { id: 'Approved', label: 'Approved', data: statusCountsByMonth.approved, color: 'green', showMark: false },
                { id: 'Denied', label: 'Denied', data: statusCountsByMonth.denied, color: 'red', showMark: false },
                { id: 'Viewed', label: 'Viewed', data: statusCountsByMonth.viewed, color: 'purple', showMark: false },
                { id: 'Pending', label: 'Pending', data: statusCountsByMonth.pending, color: 'black', showMark: false },
              ]}
              height={300}
            />
          </Paper>
        </Grid>
      </Grid>
      <Tooltip title="Go to Optic inventory" arrow placement="left">
      <Fab
        color="secondary"
        aria-label="inventory"
        onClick={() => navigate('/admin/optic-inventory-management')}
        sx={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          backgroundColor: 'primary.main', 
          '&:hover': {
            backgroundColor: 'primary.700',  
          },
        }}
      >
        <StockIcon />
      </Fab>
    </Tooltip>
    </Main>

  );
};

export default SuperAdminHome;
