import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import logo1 from '../../../assets/LogoVariation-OpticTruckWorks-02.png';
import icon from '../../../assets/Icon-OpticTruckWorks-03.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface InventoryItem {
  stock_number: string;
  make: string;
  model: string;
  size: string;
  fuel: string;
  miles: number;
  year: number;
  condition: string;
  location: string;
  type: string;
  status: string;
  seller_asking_price?: string;
}

const formatCurrency = (value: string | number | undefined): string => {
  if (value === undefined) return '';
  const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : value;
  return isNaN(numValue) ? '' : numValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const formatNumber = (value: number | string): string => {
  const numValue = typeof value === 'string' ? parseInt(value.replace(/[^0-9-]+/g, ''), 10) : value;
  return numValue.toLocaleString('en-US');
};

const convertToPDF = (items: InventoryItem[]): jsPDF => {
  const headers = [
    'Stock Number', 'Make', 'Model', 'Size', 'Fuel', 'Miles', 'Year',
    'Condition', 'Location', 'Type', 'Status', 'Sale Price'
  ];

  const rows = items.map(item => [
    item.stock_number || '',
    item.make || '',
    item.model || '',
    item.size || '',
    item.fuel || '',
    formatNumber(item.miles || 0),
    item.year || '',
    item.condition || '',
    item.location || '',
    item.type || '',
    item.status || '',
    formatCurrency(item.seller_asking_price)
  ]);

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4'
  });
  doc.setFontSize(10);

  const addLogos = (doc: jsPDF) => {
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Add logo1 at the top center
    const logo1Width = 45;
    const logo1Height = 12;
    const logo1X = (pageWidth - logo1Width) / 2;
    doc.addImage(logo1, 'PNG', logo1X, 1, logo1Width, logo1Height);

    // Add icon at the bottom center
    const iconWidth = 18;
    const iconHeight = 18;
    const iconX = (pageWidth - iconWidth) / 2;
    doc.addImage(icon, 'PNG', iconX, pageHeight - 15, iconWidth, iconHeight);
  };

  let firstPage = true;

  doc.autoTable({
    head: [headers],
    body: rows,
    startY: 14,
    theme: 'grid',
    headStyles: { fillColor: [52, 73, 94], textColor: 255 },
    styles: { fontSize: 8, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 'auto' },
      1: { cellWidth: 'auto' },
      2: { cellWidth: 'auto' },
      3: { cellWidth: 'auto' },
      4: { cellWidth: 'auto' },
      5: { cellWidth: 'auto', halign: 'right' },
      6: { cellWidth: 'auto', halign: 'center' },
      7: { cellWidth: 'auto' },
      8: { cellWidth: 'auto' },
      9: { cellWidth: 'auto' },
      10: { cellWidth: 'auto' },
      11: { cellWidth: 'auto', halign: 'right' },
    },
    alternateRowStyles: { fillColor: [242, 242, 242] },
    margin: { left: 10, right: 10 },
    didDrawPage: (data: { pageNumber: number; settings: { startY: number }; }) => {
      addLogos(doc);
      if (firstPage) {
        firstPage = false;
      } else {
        data.settings.startY = 25;
      }
    },
  });

  return doc;
};

export const downloadShareableInventoryPdf = async (collectionName: string, ids: string[]) => {
  const db = getFirestore();
  const inventoryCollection = collection(db, collectionName);

  const batchSize = 30;
  const inventoryItems: InventoryItem[] = [];

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const inventoryQuery = query(inventoryCollection, where('__name__', 'in', batchIds));
    const querySnapshot = await getDocs(inventoryQuery);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const fuel = data.fuel === 'Gasoline' ? 'Gas' : data.fuel || '';
      const item: InventoryItem = {
        stock_number: data.stock_number || '',
        make: data.make || '',
        model: data.model || '',
        size: data.size || '',
        fuel: fuel,
        miles: data.miles || 0, // Ensure it's a number
        year: data.year || '',
        condition: data.condition || '',
        location: data.location || '',
        type: data.type || '',
        status: data.status || '',
        seller_asking_price: data.seller_asking_price || '',
      };
      inventoryItems.push(item);
    });
  }

  const doc = convertToPDF(inventoryItems);
  doc.save('inventory_data.pdf');
};
