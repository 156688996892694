import { db, storage } from 'core/config/firebase'
import { addDoc, collection } from 'firebase/firestore'
import { getBytes, ref } from 'firebase/storage'
import Papa, { ParseResult } from 'papaparse'
import { ChangeEvent } from 'react'
import * as XLSX from 'xlsx'

type CsvRow = {
  WHO: string
  'STOCK NUMBER': string
  YEAR: string
  MAKE: string
  MODEL: string
  SIZE: string
  MILES: string
  FUEL: string
  Location: string
  'OUR LIST PRICE': string
  TYPE: string
  VIN: string
  'THEIR ASKING PRICE': string
  profit: string
  'SOLD DATE': string
  'TRUCK NOTES': string
  'PHONE NUMBER?': string
  LINK: string
  EMAIL: string
  '360 CAMERAS': string
  'FOLLOW UP NOTES': string
  'WHO ENTERED?': string
  'PAYOFF AMOUNT'?: string
  date_added: string
}

export const handleInventoryCsvUpload = (event: ChangeEvent<HTMLInputElement>, inventoryName: string) => {
  if (!event.target.files) return
  const file = event.target.files[0]
  Papa.parse(file, {
    header: true,
    complete: async (result: ParseResult<CsvRow>) => {
      const currentDate = new Date().toISOString();
      const vehicles: CreateVehicle[] = result.data.map(
        (item: CsvRow): CreateVehicle => ({
          who: item['WHO'],
          stock_number: item['STOCK NUMBER'],
          year: item['YEAR'],
          make: item['MAKE'],
          model: item['MODEL'],
          size: item['SIZE'],
          miles: item['MILES'],
          fuel: item['FUEL'],
          location: item['Location'],
          optic_list_price: item['OUR LIST PRICE'].replace(/[^0-9.-]+/g, ''),
          type: item['TYPE'],
          vin: item['VIN'],
          seller_asking_price: item['THEIR ASKING PRICE'].replace(/[^0-9.-]+/g, ''),
          profit: ((Number(item['THEIR ASKING PRICE'].replace(/[^0-9.-]+/g, '')) || 0) - (Number(item['OUR LIST PRICE'].replace(/[^0-9.-]+/g, '')) || 0)).toString(),
          sold_date: item['SOLD DATE'],
          truck_notes: item['TRUCK NOTES'],
          phone_number: item['PHONE NUMBER?'],
          link: item['LINK'],
          email: item['EMAIL'],
          cameras: item['360 CAMERAS'],
          follow_up_notes: item['FOLLOW UP NOTES'],
          entered_by: item['WHO ENTERED?'],
          payoff_amount: item['PAYOFF AMOUNT'] || '',
          show_on_website: false,
          date_added: currentDate
        }),
      )

      // TODO: Check if the vehicle already exists in the database

      const masterInventoryRef = collection(db, inventoryName)

      for (const vehicle of vehicles) {
        await addDoc(masterInventoryRef, vehicle)
      }
    },
    skipEmptyLines: true,
  })
}

export const readInventoryFromFirebaseCSV = async (filePath: string): Promise<Vehicle[] | undefined> => {
  const fileRef = ref(storage, filePath)

  const fileBuffer = await getBytes(fileRef)

  const csvString = new TextDecoder().decode(fileBuffer)
  return new Promise(() => {
    Papa.parse(csvString, {
      header: true,
      complete: async (result: ParseResult<CsvRow>) => {
        const vehicles: CreateVehicle[] = result.data.map(
          (item: CsvRow): CreateVehicle => ({
            who: item['WHO'],
            stock_number: item['STOCK NUMBER'],
            year: item['YEAR'],
            make: item['MAKE'],
            model: item['MODEL'],
            size: item['SIZE'],
            miles: item['MILES'],
            fuel: item['FUEL'],
            location: item['Location'],
            optic_list_price: item['OUR LIST PRICE'].replace(/[^0-9.-]+/g, ''),
            type: item['TYPE'],
            vin: item['VIN'],
            seller_asking_price: item['THEIR ASKING PRICE'].replace(/[^0-9.-]+/g, ''),
            profit: item.profit,
            sold_date: item['SOLD DATE'],
            truck_notes: item['TRUCK NOTES'],
            phone_number: item['PHONE NUMBER?'],
            link: item['LINK'],
            email: item['EMAIL'],
            cameras: item['360 CAMERAS'],
            follow_up_notes: item['FOLLOW UP NOTES'],
            entered_by: item['WHO ENTERED?'],
            payoff_amount: item['PAYOFF AMOUNT'] || '',
            show_on_website: false,
            date_added: item.date_added
          }),
        )
        return vehicles
      },
      skipEmptyLines: true,
    })
  })
}

type ExcelRow = {
  "PICKUP LOCATION": string
  "YEAR": string
  "MAKE": string
  "MODEL": string
  "SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)": string
  "VIN": string
  "MILES": string
  "DESIRED PRICE": string
  "LIEN (Yes/No) ": string
  "FINANCING BANK": string
}

export const readInventoryFromXLSX = async (fileBase64: string): Promise<Omit<Vehicle, 'id'>[]> => {
  const workbook = XLSX.read(fileBase64, { type: 'base64' })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const data = XLSX.utils.sheet_to_json(worksheet, { blankrows: false, defval: '' })

  //someone deleted a row and it shifted everything so im just going to find the header row dynamically for now like this 
  let headers: string[] = []
  let headerRowIndex = -1

  for (let i = 0; i < data.length; i++) {
    const row = Object.values(data[i] as string[])
    if (row.includes("PICKUP LOCATION")) {
      headers = row
      headerRowIndex = i
      break
    }
  }

  if (headerRowIndex === -1) {
    throw new Error('Could not find header row, please make sure the uploaded spreadsheet matches the template')
  }

  const rows = data.slice(headerRowIndex + 1) as string[][]

  const vehicles = rows.map((row) => {
    const item = Object.fromEntries(headers.map((header, index) => [header, Object.values(row)[index]])) as ExcelRow
    return {
      year: String(item['YEAR'] || ''),
      make: String(item['MAKE'] || ''),
      model: String(item['MODEL'] || ''),
      size: String(item['SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)'] || ''),
      miles: String(item['MILES'] || ''),
      location: String(item['PICKUP LOCATION'] || ''),
      vin: String(item['VIN'] || ''),
      seller_asking_price: String((item['DESIRED PRICE'] || '')).replace(/[^0-9.-]+/g, ''),
      show_on_website: false,
      who: '',
      stock_number: '',
      fuel: '',
      optic_list_price: '',
      type: '',
      profit: '',
      sold_date: '',
      truck_notes: '',
      phone_number: '',
      link: '',
      email: '',
      cameras: '',
      follow_up_notes: '',
      entered_by: '',
      payoff_amount: '',
    }
  })

  return vehicles
}
