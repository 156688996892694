import { format, toZonedTime } from 'date-fns-tz'

export function generateStockNumber(existingStockNumbers: string[], firstName: string, lastName: string) {
  const prefix = firstName.charAt(0).toUpperCase().concat(lastName.charAt(0).toUpperCase())
  const relevantCodes = existingStockNumbers
    .filter(code => code.startsWith(prefix))
    .map(code => parseInt(code.substring(2, 10)))
  const nextNumber = Math.max(...relevantCodes) + 1
  const nextCode = `${prefix}${nextNumber.toString().padStart(5, '0')}`
  return nextCode
}

export const getTextWidth = (text: string, font: string = '14px Arial'): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }
  return 0
}

export const formatPrice = (value: string | undefined) => {
  if (!value || value.trim() === '' || isNaN(Number(value))) return ''
  const numValue = Number(value)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(numValue) ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(numValue)
}

export const formatDistance = (value: string | undefined) => {
  if (!value || value.trim() === '' || isNaN(Number(value))) return ''
  const numValue = Number(value)
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(numValue)
}

export const formatDateTime = (value: string | undefined) => {
  if (!value) return ''
  const utcDate = new Date(value)
  const easternTime = toZonedTime(utcDate, 'America/New_York')

  return format(easternTime, 'MM/dd/yyyy hh:mm a')
}

export default { generateStockNumber }
